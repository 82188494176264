import {
    Object
} from "core-js";
// import {
//     Config
// } from "../../utils/index";
import {
    getOssKey
} from "../common.js"
// import Store from "store"

// const baseUrl = Config.api.url;

export default async (type, url, data) => {
    // let localToken = Store.get(Config.store.token);
    type = type.toUpperCase();
    // 获取oss签名
    let ossKeyData = (await getOssKey()).data
    let commitData = Object.assign(ossKeyData, data)
    
    let fileNameArr = commitData.file.name.split('.')
    let fileName = commitData.file.name.split('.')
    fileName = fileName.filter((itemName, indexName) => { return indexName !== (fileName.length - 1) })
    commitData.name = fileName.join('.')
    commitData.fileType = fileNameArr[fileNameArr.length - 1]

    // commitData.name = commitData.file.name.split('.')[0]
    // commitData.fileType = commitData.file.name.split('.')[commitData.file.name.split('.').length-1]
    let testData = {
        policy: commitData.policy,
        signature: commitData.signature,
        ossaccessKeyId: commitData.accessKeyId,
        key: `${commitData.dir}/${commitData.name}${(new Date().getTime())}.${commitData.fileType}`,
        dir: commitData.dir,
        host: commitData.host,
        callback: commitData.callback,
        file: commitData.file
    }
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        for (let item in testData) {
            formData.append(`${item}`, testData[item]);
        }
        let oReq = new XMLHttpRequest();
        oReq.onreadystatechange = function () {
            if (oReq.readyState === 4) {
                if (oReq.status === 200) {
                    let json = JSON.parse(oReq.responseText);
                    if (json) {
                        if (json.code != "200") {
                            this.$message.error(json.message)
                            return;
                        } else {
                            resolve(json);
                            return;
                        }
                    }
                } else {
                    reject(oReq);
                }
            }
        };
        oReq.open("POST", commitData.host);
        oReq.send(formData);
    })
};